* { transition: all 0.5s ease; }

.limitedPresale {    font-weight: bold;
    font-size: 120%;
    margin-bottom: 10px;
    color: #008000;}

.headerLeft {float:left; width:49%; padding-left:1%; text-align:left; }

.headerRight {float:right; width:50%; }
a.pageButton.logowords { color:white; font-weight: 100; font-size: 20px;}

.crpics { overflow: hidden;}
.flex-left { flex: 100% 1;    padding: 10px;}
.crpics-1 { display: flex;    flex-direction: column;    left: -5vw;    line-height: 0;     position: absolute; top: -165px;  -webkit-transform: matrix(-.97,-.26,-.26,.97,0,0); transform: matrix(-.97,-.26,-.26,.97,0,0); }
.crpics-1 li { flex: auto;    margin-bottom: 20px; }
.crpics-1 a { display: block;} 
.crpics-1 img { border-radius: 20px; height: 320px; -o-object-fit: cover;  object-fit: cover;    -o-object-position: center; object-position: center;  width: 320px; }
@media only screen and (max-width: 1366px)  { .crpics-1 img { height: 23vw; width: 23vw;
} }
.crpics-2 { display: flex; flex-direction: column; left: 22vw; line-height: 0;    position: absolute; top: -266px;  -webkit-transform: matrix(-.97,-.26,-.26,.97,0,0); transform: matrix(-.97,-.26,-.26,.97,0,0); }
.crpics-2 li { flex: auto;    margin-bottom: 20px; }
.crpics-2 a { display: block; }
.crpics-2 img { border-radius: 20px;    height: 320px;    -o-object-fit: cover;
    object-fit: cover;    -o-object-position: center;    object-position: center;    width: 320px; }
@media only screen and (max-width: 1366px) {.crpics-2 img {    height: 23vw;    width: 23vw; } }
.fcontainer { display: flex;    flex-direction: row;    font-size: 30px; text-align: center; }
@media only screen and (max-width: 575px) {.fcontainer {
    flex-direction: column;    margin-top: 0;}}

.flex-left {     flex: 100% 1;    padding: 10px; }
.show575 { display:none; }
.saleMessage{ font-weight: bold;
    font-size: 18px !important;
    width: 100% !important;}
#prepare{position: relative; top: 100px;}

@media only screen and (max-width: 575px) { 
    .flex-item-left { flex: none;  height: 500px; margin-top: -55px; padding: 0; }
    .crpics { display: none; }
    div.mintBoxRight { width:100% !important; padding:0 !important;  } 
    .show575 { display:block; margin=bottom:20px; }
    div.mintBoxWrapper { margin-top:0 !important; }
    #prepare{left: initial;}
    #prepare button, #DAppArea button {font-size: 60% !important;}
}

@media only screen and (max-width: 994px) {  .crpics-mid { top: 0; } }

#root {    flex: 100% 1;  padding: 10px;}

/* ############################################################################ */

.sub-text { font-size: 60px;
    font-weight: 900;
    letter-spacing: -1px;
    margin: 0;
    background: #0000007d;
    border-radius: 35px; 
    color:#008000;
}
div.theSlider {     color: #008000;
    background: #1f2326;
    border-radius: 35px;}

div.clear {    background: none;    border: 0;    clear: both;    display: block;
    float: none;    font-size: 0;    list-style: none;    margin: 0;    padding: 0;
    overflow: hidden;    visibility: hidden;    width: 0;    height: 0; }

button {cursor: pointer; }
#theMintButton { width:100%;padding: 10px 25px 8px 25px !important; }
.buttonMessage{    font-size: 70%;
    color: white;}
.buttonMessage a {color: white; text-decoration: underline;}

body {
    color: black;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: black;
    /*background: url(Inside-Bar-Fireworks.gif);
    background-size: auto;*/
}

a {
    color: #99a9a8;
    text-decoration: none;
}

.white {
    color: white;
}

.noCollectionSpacer { height:30px; }

.smaller {
    font-size: 80%;
}

.fixcaps {
    text-transform: initial !important;
}

div.MiddleSection {
    color: black;
    display: grid;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.GridItem {
    align-self: center;
    @media(max-width: 765px) {
        margin-bottom: 1rem;
    }
}

div.Welcome {
    text-transform: uppercase;
    font-size: 3vw;
    @media(max-width: 800px) {
        font-size: 4vw;
    }
}

div.MaxPerWallet {
    color: rgb(255 255 255 / 60%);
    font-size: 1.1rem;
    @media (max-width: 764px) {
        font-size: 4vw;
    }
}

div.PriceWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

div.PriceText {
    font-size: 2vw;
    @media(max-width: 764px) {
        font-size: 4vw;
    }
}

div.AmountWrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

button.CounterBtnWrap {
    border: 0;
    background: transparent;
    color: white;
    font-size: 4vw;
    cursor: pointer;
    @media (max-width: 764px) {
        font-size: 10vw;
    }
}

div.CounterInput {
    background: transparent;
    border: solid white 2px;
    text-align: center;
    width: 15%;
    margin-right: 2rem;
    margin-left: 2rem;
    font-size: 1rem;
    padding: 1rem;
    @media( max-width: 764px) {
        width: 30%;
    }
}

input.slider {}

div.MintBtn {
    margin: 2rem 0;
    background: #d94745 !important;
    color: #0F0F0F !important;
    cursor: pointer;
    text-transform: uppercase;
    justify-self: self-start;
    border: 0 !important;
    font-size: 1.5vw;
    transition: all .2s;
}

.MintBtn:hover {
    color: white !important;
    background: black !important;
    transition: all .2s;
}

div.SoldFont {
    font-size: 1.4rem;
    color: white;
    @media (max-width: 765px) {
        font-size: 2rem;
    }
}

img.CollectionImage {
    width: 65%;
    border-radius: 10px;
}

div.LoadingFont {
    font-size: 1.5vw;
    color: white;
}

div.CountDownTimer {}

#pageLogo img {
    width: 100%;
}

#pageLogo {
    width: 100px;
    height: 100px;
    position: absolute;
    top: 15px;
    left: 9%;
}



#saleStatus {
    font-size: 150%;
    color: #fff;
    text-decoration: overline;
    text-decoration-color: #00ffa7;
    text-decoration-thickness: from-font;
    text-decoration-style: wavy;
}

#theStatus {
    padding-left: 15px;
    color: white;
}

.pageHighlightColor {
    color: #008000;
}

.pageHighlightColor2 {
    color: #6a624a;
}

#branding {
    padding-top: 20px;
}

#branding img {
    border: 2px solid black;
    -webkit-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    -moz-box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
    box-shadow: 4px 7px 30px -17px rgba(252, 252, 252, 0.58);
}

#navButtons {
    margin-top: 20px;
}

img.navIcons {
    position: relative;
    top: 3px;
    left: -7px;
    width: 20px;
    height: 20px;
}

.logo-wallet {
    width: 90px;
    height: 90px;
}
.walletName{

    font-weight: 100;
    font-size: 150%;
    vertical-align: top;
    display: block;
}

div.ReactModal__Content.ReactModal__Content--after-open .providerChoices:hover { background:#008000; color:white; }

#DAppArea button { margin-top:15px;  }
#totalPrice { margin-top:15px; margin-bottom:15px;  }
#prepare button, #DAppArea button {    background: #008000;
    color: white;
    border: initial;
    padding: 10px 20px 10px 20px;
    border-radius: 45px;
    text-transform: uppercase;
    transition: transform 200ms ease;
    padding: 20px 25px 17px 25px;
    font-weight: 600;
    font-size: 100%;}

#prepare button:hover, #DAppArea button:hover {     background: #008000;
    color: white;
    transform: translate(0px, -2px); }

div.ReactModal__Content.ReactModal__Content--after-open {    background: #ffffff !important;    text-align: center; border-radius: 56px !important; padding-top: 10px !important; border: 1px gray solid !important; box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65);
    -webkit-box-shadow: 0px 12px 24px -12px rgba(0, 0, 0, 0.65);}
div.ReactModal__Content.ReactModal__Content--after-open h2 {  color: black !important;     font-size: 160%;     margin-bottom: 10px;    margin-top: 6px;     font-family: 'Roboto', sans-serif;
}
div.ReactModal__Content.ReactModal__Content--after-open button {    background: white;    border: initial;    padding: 5px;    border-radius: 10px;     margin-bottom: 15px; }
div.ReactModal__Content.ReactModal__Content--after-open .providerChoices {     width: 100%;  margin-bottom: 10px; background: #fff;  border: 1px solid gray;    border-radius: 10px;    
    margin-top: 10px; }

    .topBanner img {

    }
    
    .topBanner {
        width:100%;
        padding: 0px;
        padding-bottom: 0;
        background-attachment: fixed;
        text-align: center;
    }

.topBannerWrapper { 
    width:90%; 
    max-width: 1200px;   
     margin-left: auto;  
       margin-right: auto; }

.topBannerLeft { float: left;
    width: 20%; }

.topBannerRight { width: 80%;
    float: right;
    margin: 0;
    padding: 0;
    text-align: right; 
    padding-top: 4%; }

    .topBannerRight a { color: #008000;
        padding-left: 9px;
        padding-right: 9px;
        font-size: 100%;
        background: #ffe80069;
        margin-left: 15px;
        border-radius: 10px;}
    .topBannerRight a:hover { color: #ffffff; background: #008000; }

.topBannerButton {
    padding: 3px 10px 3px 10px;
    border-radius: 10px;
    box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -webkit-box-shadow: 3px 3px 11px rgb(0 0 0 / 40%);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
    background: black;
    margin: 20px 20px 20px 20px;
}

.topBannerButton:hover {
    background: #d54aff;
}

#DAppArea h2 {
    color: white;
    font-size: 200%;
    margin-top: 0;
    font-weight: 600;font-family: "Roboto",sans-serif;
    text-transform: uppercase;
}

#DAppArea h3 {
    color: #6a624a;
    font-style: italic;
    font-size: 150%;
    font-weight: 100;
}

#walletButton {}

.white { color: white; }

.qty { color: #364050; }

button:hover .qty { color: white; }

.welcome {
    text-align: center;
    font-size: 135%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 30px;
    MARGIN-TOP: 0;
    letter-spacing: 1px;
}

span.copyrightNotice {
    font-size: 65%;
}

.dropLogo { text-align:center; padding-bottom: 10px; }
.dropLogo img { width: 150px; }

.allButMobile  { }
.allButMobile img {max-width: 200px;     margin: 25px; }


div.mintBoxWrapper {
    background: #0000009c;
    /* width: 100%; */
    /* display: flex; */
    /* overflow: auto; */
    margin: 5% auto 20px auto;
    max-width: 1200px;
    /* border: 4px solid #008000; */
    border-radius: 10px;
    color: #ffe800cf;
    position: relative;
    top: 100px;
}

div.aboveMintBox {  
    width: 90%;
    display: flex;
    overflow: auto;
    margin: 15px auto 20px auto;
    max-width: 1200px;

}
.supplyInfo {    font-size: 125%;}
div.mintBoxLeft {
    width: 40%;
    padding-left: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}

div.mintBoxRight {
    width: 90%;
    padding: 20px;
    float: left;
    text-align: center;
    padding: 2%;
}

.mobileOnly {
    display: none;
}
.theSlider input {    position: relative;    top: -6px; }

#currentMintCount {}

/* YOUR COLLECTION */

.collectionWrapper {
    padding: 1% 2% 3% 2%;
    background: #1c1c1c;
    text-align: center;
    background: black;
    background-image: url(https://yearofthewoman.io/wp-content/uploads/2022/02/4907157-scaled.jpg?id=251) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

input.pageButton span {}

.collectionWrapper h2 {
    font-size: 225%;
}

.collectionRow {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

#renderCollection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 3rem;
    margin-top: 2rem;
    max-width: 1700px;
    margin-left: auto;
    margin-right: auto;
}

.collectionItem {}

.collectionImage {
    width: 75%;
    border-radius: 10px;
    vertical-align: middle;
    border: 4px solid saddlebrown;
}

.collectionTokenTitle {
    margin-top: 1rem;
    color: saddlebrown;
    font-size: 125%;
}

#prepare p {font-size: 80%;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    color: white;
    line-height: 20px;}

.header {background: #0000009c;
    width: 100%;
    height:70px;
    position:absolute;
    padding: 0;
    text-align: right;
    color: black;
    padding-top:22px;
}

.footer {
    border-top: 1px solid black;
    width: 100%;
    padding: 0;
    text-align: center;
    color: black;
    padding-bottom: 8px;
    padding-top: 7px;
    background: white;
    bottom : 0px;  
    height : 40px;  
    margin-top : 40px;  
    text-align: center;  
    vertical-align: middle; 
    position:fixed;
    width:100%;

}

a.pageButton {
    color: #00ffa7;
    padding: 0px 0px 0px 10px;
    margin: 0 1% 0 1%;
    white-space: nowrap;
    position: relative;
    transition: transform ease 200ms;
    display: inline-block;
    border-radius: 10px;
    font-weight: 600;
}

a.pageButton:hover {
    background: #00ffa7;
    transform: translate(0, -2px);
}
a.pageButton.logowords:hover {
    background: initial;
    transform: translate(0, -2px);
}
.marginright { margin-right:30px !important; }
.bannerButton {
    padding: 9px 13% 8px 13%;
    line-height: 250%;
    border-radius: 10px;
    box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -webkit-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 3px 3px 11px rgba(0, 0, 0, 0.4);
    white-space: nowrap;
}

.bannerButton:hover {
    background: #d54aff;
}

#HeaderButtons {
    margin-top: 20px;
    position: absolute;
    top: 1%;
    right: 6%;
}

.header img {
    position: relative;
    top: 3px;
    left: -4px;    width: 30px;
}
.header img.thelogo{    width: 36px;}

.footer img {
    position: relative;
    top: 3px;
    left: -4px;
    width: 20px;
}


.credits {
    background: #778384;
    text-align: center;
    font-size: 80%;
    letter-spacing: 1px;
    color: white;
}

.credits a {
    color: black;
}

.larger {
    font-size: 200%;
    font-weight: 100;
    letter-spacing: initial;
}

input[type=range] {
    width: 30%;
    margin: 8px 0;
    background-color: transparent;
    -webkit-appearance: none;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    background: #ffffff;
    border: 1px solid #838585;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-webkit-slider-thumb {
    margin-top: -9px;
    width: 33px;
    height: 22px;
    background: black;
    border: 4px solid #008000;
    border-radius: 10px;
    cursor: pointer;
    -webkit-appearance: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ffffff;
}

input[type=range]::-moz-range-track {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 25px;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-moz-range-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 10px;
    cursor: pointer;
}

input[type=range]::-ms-track {
    background: transparent;
    border-color: transparent;
    border-width: 12.1px 0;
    color: transparent;
    width: 100%;
    height: 6px;
    cursor: pointer;
}

input[type=range]::-ms-fill-lower {
    background: #ebebeb;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-fill-upper {
    background: #ffffff;
    border: 1px solid #dbd58f;
    border-radius: 50px;
}

input[type=range]::-ms-thumb {
    width: 33px;
    height: 22px;
    background: gold;
    border: 4px solid black;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 0px;
    /*Needed to keep the Edge thumb centred*/
}

input[type=range]:focus::-ms-fill-lower {
    background: #ffffff;
}

input[type=range]:focus::-ms-fill-upper {
    background: #ffffff;
}

@media (min-width: 1400px) {
    #renderCollection {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 1130px) {

    .topBanner img { width: 175%; }
    .topBannerRight a { }

}

@media (max-width: 800px) {
    .mobileOnly { display: block; margin: 10px auto 20px auto; max-width: 60%; }
    #renderCollection { grid-template-columns: 1fr 1fr; }
    #amountSold {}
    .topBanner { background: #ffffff; min-height: 80px;  }
    .footer { display:none; }
    .topBannerRight, .topBannerLeft { width: 100%; float: initial; text-align: center; }
    .topBanner img { width: 90%; }
    .allButMobile { display:none; }
    .header { height: 30px; padding-top: 9px; }
}
